.bg-body-tertiary{
background-color: black!important;
}
.sticky-navbar {
    position: -webkit-sticky;
    position: sticky!important;
    top: 0;
    z-index: 1030; /* Bootstrap's z-index for fixed/sticky elements */
  }
.nav-link{
    color: white!important;
}
.navbar-toggler-icon{
    background-image: url("../assets/images/bar.svg")!important;
}
.navbar-nav{
    justify-content:flex-end!important;
}
@media (min-width: 992px)
    {.navbar-expand-lg .navbar-collapse {
    display:flex!important;
    flex-basis:auto;
    justify-content:flex-end!important;
}
}
.dropdown-toggle::after{
    margin-left:5px!important;
}
