.footer-last-pbx{
    background-color: #222222!important;
    width:100%;
   
}
.footer-para{
    color:#ffff;
    font-family:Source Sans Pro;
    font-size: 18px;
    line-height:2;
}
.social-icons{
fill:white;
font-size:20px;
}
.list-heading{
    color:white;
    font-family:sans-serif;
    font-size:25px;
    padding-bottom: 20px;
}
.list-sec{
    margin: 20px 20px 20px 20px;
}
.wow-list{
    padding:0;
}
.wow-list li{
    list-style:none;
    font-family: Source Sans Pro;
    font-size: 18px;
    padding-bottom: 10px;
}
.wow-list a{
    color:#777777;
}
.copyright{
    background: #eeeeee;
    padding: 10px 0px;
}
.copy-para{
    margin-bottom:0px;
    font-family: Source Sans Pro;
    font-size:14px;
}
@media (max-width:768px)
{
    .copy-para{
        text-align:center;
    }
    .wow-list{
        text-align:center;
    }
    .list-heading{
        text-align: center;
    }
}
@media (max-width:992px){
    .footer-logo{
        width:250px;
    }
}
.about-us-style{
    color:#317b22;
    font-size: 25px;
}
.coneger-mail a{
    text-decoration: none;
    color:#ffff!important;
}
.sort-by-drop{
    background-color:white!important;
    border-color:#ffff!important;
    color:black!important;
    width:250px;
}
.dropdown-item{
    color: black!important;
}
.drop-foot::after {
    margin-left: 160px!important;
}