a{
  text-decoration: none!important;
  color:#ffff!important;
}
/* main-page -header-section */
@media (max-width:768px){
.para-mobile{
padding-bottom: 20px;
}
}
.main-banner-head{
  overflow:hidden;
}
.yellow-bg{
  background-color:#f1c00a;
  height: 150px;
}
.person{
  width: 100%;
  height: 480px;
}
p{
  margin-bottom: 0!important;
}
.black-bg{
  background-color:black;
}
.machine{
  border-radius:50%;
  width: 150px;
  height: 150px;
}
.category{
  text-align: center;
  color:#ffff;
  padding-top: 10px;
}
.category-img{
  text-align: center;
}
@media (max-width:552px)
{
  .yellow-bg{
  height:200px;
  }
}
.what-section{
  text-align: center;
  border-bottom: 1px solid #f1c00a;
}
.what-head-sub{
  font-size: 23px;
}
.what-sub-2{
  font-size:18px;
}
.recent-project{
  background-color:black;
  overflow: hidden;
}
.recent-heading{
  color: #ffff;
  text-align:center;
  border-bottom: 1px solid #f1c00a;
}
/* .project-img{
  width: 100%!important;
  height: 350px!important;
} */
.recent-project {
  background-color: black;
  overflow: hidden;
  height: 550px;
  position: relative;
  white-space: nowrap;
}

.owl-sider.owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.owl-sider.owl-nav button.owl-prev {
  left: 0;
}
.owl-sider.owl-nav button.owl-next {
  right: 0;
}

.owl-sider.owl-dots {
  text-align: center;
  padding-top: 15px;
}
.owl-sider.owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}
.owl-sider.owl-dots button.owl-dot.active {
  background-color: #000;
}
.owl-sider.owl-dots button.owl-dot:focus {
  outline: none;
}
.owl-sider.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.38) !important;
}
.owl-slider span {
    font-size: 70px;    
    position: relative;
    top: -5px;
}
.owl-sider.owl-nav button:focus {
    outline: none;
}
.partner-head{
  text-align: center;
  border-bottom: 1px solid #f1c00a;
}
.yellow-bg-1{
  background-color:#f1c00a;
  height:100px;
}
@media (max-width:552px){
  .yellow-bg-1 {
height:150px;
  }
}
.contact-head{
  text-align: center;
}
@media (max-width:768px){
.what-mobile{
padding-top:50px;
}
.what-last-mobile{
  padding-bottom:30px;
}
}
.pin{
  font-size:40px;
  margin-right: 10px;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background-color: #f7f7f7;
}

.contact-form {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 30px;
}

.contact-form h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}

.contact-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #555;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.contact-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* careeer */
.table>:not(caption)>*>* {
  padding: 2.5rem 1.5rem!important;
}
.job-icon::before{
  content:url("../src/assets/images/jobicon.svg")!important;
  margin:0px 10px;
}
.loc-icon::before{
  content:url("../src/assets/images/location-orange.svg");
  margin:0px 2px;
}
.cal-icon::before{
  content:url("../src/assets/images/calendar-orange.svg");
}
/* Contact page css*/
.cv-border{
  border-bottom: 1px solid #333;
  padding-bottom:20px;
  padding-top: 20px;
}
.hr-consger{
  color:black!important;
}
.table-row:hover{
  color:red;
}
tr:hover{
  transform: translateY(-5px); /* Move the row up by 5px on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}
/* news section */
.card{
  display:flex;
  flex-direction:row!important;
}
.news{
  border-bottom: 4px solid #256d89 !important;
  color: #256d89 !important;
  width:50px;
  font-family:'Times New Roman', Times, serif;
  font-weight:600;
}
.media{
  display: flex;
}
.card-heading{
  color:#256d89;
  font-weight:600;
}
.card-para{
  color:#256d89;
}
@media (max-width:520px){
  .media{
 display:flex;
 flex-direction:column;
  }
}

/* toltip css */
.table-row {
  position: relative; /* Needed for the tooltip positioning */
}

.table-row:hover .tooltip {
  visibility: visible;
  opacity: 1;
}


.tooltip {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position the tooltip above the row */
  left: 50%;
  margin-left: -110px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow pointing down */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.address-bar{
  font-size: 18px;
}
